body {
  font-family: Roboto,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  color: #727272;
  background: #fff;
}

.h1, h1, h5 {
  color: #121212;
  font-family: Roboto;
}

.title {
  margin-top: 15px;
  font-size: 4.2rem;
  font-weight: 100;
}

.card {
  color: #333;
}

.seth-code {
  background-color: #ddd;
  color: #333;
}

footer {
  padding-top: 10px;
  background-color: #0B141F;
  color: #fff;
}
